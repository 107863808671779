@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --tg-theme-bg-color: #ffffff;
  --tg-theme-text-color: #000000;
  --tg-theme-subtitle-text-color: #999999;
  --tg-theme-hint-color: #999999;
  --tg-theme-accent-text-color: #3a6d99;
  --tg-theme-destructive-text-color: #dd3e3e;
  --tg-theme-link-color: #2481cc;
  --tg-theme-button-color: #5288c1;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-header-bg-color: #5288c1;
  --tg-theme-secondary-bg-color: #f0f0f0;
  --tg-theme-section-header-text-color: #3a6d99;
  --tg-theme-section-separator-color: #e0e0e0;
}

.dark {
  --tg-theme-bg-color: #1c1c1d;
  --tg-theme-text-color: #ffffff;
  --tg-theme-subtitle-text-color: #7d7d7d;
  --tg-theme-hint-color: #7d7d7d;
  --tg-theme-accent-text-color: #64baf0;
  --tg-theme-destructive-text-color: #eb5545;
  --tg-theme-link-color: #64baf0;
  --tg-theme-button-color: #50a8e2;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-header-bg-color: #1c1c1d;
  --tg-theme-secondary-bg-color: #2c2c2e;
  --tg-theme-section-header-text-color: #64baf0;
  --tg-theme-section-separator-color: #3d3d3f;
}
